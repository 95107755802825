///
/// Dimension by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Form */

	form {
		$gutter: _size(element-margin) * 0.75;

		margin: 0 0 (_size(element-margin) * 1.25) 0;

		.field {
			margin: 0 0 ($gutter * 1) 0;

			&.half {
				width: 50%;
				float: left;
				padding: 0 0 0 ($gutter * 1 * 0.5);

				&.first {
					padding: 0 ($gutter * 1 * 0.5) 0 0;
				}
			}
		}

		> .actions {
			margin: ($gutter * 1.25) 0 0 0 !important;
		}

		@include breakpoint(small) {
			.field {
				margin: 0 0 ($gutter * 0.75) 0;

				&.half {
					padding: 0 0 0 ($gutter * 0.75 * 0.5);

					&.first {
						padding: 0 ($gutter * 0.75 * 0.5) 0 0;
					}
				}
			}

			> .actions {
				margin: ($gutter * 1) 0 0 0 !important;
			}
		}

		@include breakpoint(xsmall) {
			.field {
				&.half {
					width: 100%;
					float: none;
					padding: 0;

					&.first {
						padding: 0;
					}
				}
			}
		}
	}

	label {
		color: _palette(fg-bold);
		display: block;
		font-size: 0.8rem;
		font-weight: _font(weight);
		letter-spacing: _font(letter-spacing);
		line-height: 1.5;
		margin: 0 0 (_size(element-margin) * 0.5) 0;
		text-transform: uppercase;
	}

	input[type="text"],
	input[type="url"],
	input[type="file"]
	select,
	textarea {
		@include vendor('appearance', 'none');
		@include vendor('transition', (
			'border-color #{_duration(transition)} ease-in-out',
			'box-shadow #{_duration(transition)} ease-in-out',
			'background-color #{_duration(transition)} ease-in-out'
		));
		background: transparent;
		border-radius: _size(border-radius);
		border: solid _size(border-width) _palette(border);
		color: inherit;
		display: block;
		outline: 0;
		padding: 0 1rem;
		text-decoration: none;
		width: 100%;

		&:invalid {
			box-shadow: none;
		}

		&:focus {
			background: _palette(border-bg);
			border-color: _palette(fg-bold);
			box-shadow: 0 0 0 _size(border-width) _palette(fg-bold);
		}
	}

	select {
		option {
			background: _palette(bg);
			color: _palette(fg);
		}
	}

	.select-wrapper {
		@include icon;
		display: block;
		position: relative;

		&:before {
			color: _palette(border);
			content: '\f107';
			display: block;
			height: _size(element-height);
			//line-height: _size(element-height);
			line-height: calc(#{_size(element-height)} + 0em);
			pointer-events: none;
			position: absolute;
			right: 0;
			text-align: center;
			top: 0;
			width: _size(element-height);
		}

		select::-ms-expand {
			display: none;
		}
	}

	input[type="text"],
	input[type="url"],
	input[type="file"]
	select {
		height: _size(element-height);
	}

	textarea {
		padding: 0.75rem 1rem;
	}

	input[type="checkbox"],
	input[type="radio"], {
		@include vendor('appearance', 'none');
		display: block;
		float: left;
		margin-right: -2rem;
		opacity: 0;
		width: 1rem;
		z-index: -1;

		& + label {
			@include icon;
			@include vendor('user-select', 'none');
			color: _palette(fg);
			cursor: pointer;
			display: inline-block;
			font-size: 0.8rem;
			font-weight: _font(weight);
			margin: 0 0 (_size(element-margin) * 0.25) 0;
			padding-left: (_size(element-height) * 0.6) + 1rem;
			padding-right: 0.75rem;
			position: relative;

			&:before {
				@include vendor('transition', (
					'border-color #{_duration(transition)} ease-in-out',
					'box-shadow #{_duration(transition)} ease-in-out',
					'background-color #{_duration(transition)} ease-in-out'
				));
				border-radius: _size(border-radius);
				border: solid _size(border-width) _palette(border);
				content: '';
				display: inline-block;
				height: (_size(element-height) * 0.6);
				left: 0;
				//line-height: (_size(element-height) * 0.575);
				line-height: calc(#{_size(element-height) * 0.575} + 0em);
				position: absolute;
				text-align: center;
				top: -0.125rem;
				width: (_size(element-height) * 0.6);
			}
		}

		&:checked + label {
			&:before {
				background: _palette(fg-bold) !important;
				border-color: _palette(fg-bold) !important;
				color: _palette(bg);
				content: '\f00c';
			}
		}

		&:focus + label {
			&:before {
				background: _palette(border-bg);
				border-color: _palette(fg-bold);
				box-shadow: 0 0 0 _size(border-width) _palette(fg-bold);
			}
		}
	}

	input[type="checkbox"] {
		& + label {
			&:before {
				border-radius: _size(border-radius);
			}
		}
	}

	input[type="radio"] {
		& + label {
			&:before {
				border-radius: 100%;
			}
		}
	}

	::-webkit-input-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	:-moz-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	::-moz-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	:-ms-input-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}

	.formerize-placeholder {
		color: _palette(fg-light) !important;
		opacity: 1.0;
	}